<template>
  <div>
    <div :class="cols === '6' ? 'ml-5' : 'mt-2'">
      <validation-observer v-slot="{ invalid }">
        <b-row>
          <b-col class="settings-title" cols="10">
            {{ $t('views.top-bar.settings.general-informations.contacts.title') }}
          </b-col>
          <b-col v-if="!editMode" align="right" cols="2">
            <i
              @click="editMode= !editMode"
              class="d-icon-gray icofont icofont-ui-edit pointer"
            />
          </b-col>
          <b-col v-else align="right" cols="2">
            <i
              @click="onSave(invalid)"
              class="d-icon-gray btn-check-size icofont icofont-check pointer"
            />
            <i
              @click="onAbort"
              class="d-icon-gray btn-close-size icofont icofont-close pointer"
            />
          </b-col>
        </b-row>
        <div :class="$store.getters['layout/getInnerWidth'] < 770 ? 'text-center' : ''">
          <contacts-details
            :club="club"
            :cols="cols"
            :edit-mode="editMode"
            :validation="validation"
          />
        </div>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import {putClub} from "@api/doinsport/services/club/club.api";
import {SUCCESS} from "@plugins/flash";

export default {
  data: () => ({
    editMode: false,
  }),
  components: {
    ContactsDetails: () => import('@form/settings/complex-contacts/DetailForm')
  },
  props: {
    validation: {
      type: Object,
      default: () => require('@validation/entities/doinsport/Club.json'),
    },
    club: {
      type: Object,
      default: () => {
      },
    },
    cols: {
      type: String,
      default: '6',
    }
  },
  methods: {
    onSave(invalid) {
      if (invalid) {
        this.$flash(this.$t('general.actions.error'), this.$t('validation.check-fields'));
      } else {
        putClub(this.club.id, {
          fixPhoneNumber: this.club.fixPhoneNumber,
          mobilePhoneNumber: this.club.mobilePhoneNumber,
          email: this.club.email,
          webSiteUrl: this.club.webSiteUrl,
          socialNetworks: this.club.socialNetworks,
        })
          .then((response) => {
            this.$emit('on:reload-club');
            this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
            this.editMode = !this.editMode;
          })
        ;
      }
    },
    onAbort() {
      this.editMode = !this.editMode;
      this.$emit('on:reload-club');
    },
    getCols(x) {
      const width = this.$store.getters["layout/getInnerWidth"];
      if (this.cols === '12') {
        if (width < 767) {
          return 12;
        }
        return '6';
      }
      if (width < 1967) {
        if (x === 1) {
          return 2;
        } else if (x === 2) {
          return 3
        } else if (x === 3) {
          return 4;
        }
      } else {
        return x;
      }
    }
  }
}
</script>
<style scoped>
.social [class*="fa fa-"] {
  background-color: #30A6D8;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin: auto 3px;
  width: 30px;
  font-size: 15px;
  text-align: center;
}

.btn-close-size {
  font-size: 20px;
}

.btn-check-size {
  font-size: 22px;
}

.link-color {
  color: #0B2772;
}
</style>
